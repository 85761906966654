import React, { Fragment, useEffect } from "react";
import styles from './DashboardPage.module.css';
import ResponsiveAppBar from "../../Navs/ResponsiveAppBar/ResponsiveAppBar ";
import SearchModule from "../../SearchModule/SearchModule";
import { Backdrop, Box, CircularProgress, Drawer, Grid, useMediaQuery, useTheme } from "@mui/material";
import AnalyticsMenu from "../../AnalyticsMenu/AnalyticsMenu";
import ReactChart from "../../ReactChart/ReactChart";
import ChartsContainer from "../../ChartsContainer/ChartsContainer";
import axios from "axios";
import { fetchVehicles, resetVehicleRedux } from "../../../features/vehicles/vehiclesSlice";
import { useDispatch, useSelector } from "react-redux";
import useUserValidation from "../../../hooks/useUserValidation/useUserValidation";




const DashboardPage = () => {
    const { checkToken, logged, loadingUser } = useUserValidation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(600));
    const desktop = useMediaQuery(theme.breakpoints.up(900));

    const dispatch = useDispatch();

    const getVehicles = useSelector(fetchVehicles)

    useEffect(() => {
        dispatch(resetVehicleRedux());
        checkToken(() => {
            getVehicles(dispatch);
        });
    }, [])

    return (
        <Fragment >
            <ResponsiveAppBar />
            <Box sx={{
                position: "sticky",
                top: -20, //desktop ? "64px" : mobile ? "56px" : "64px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                boxSizing: "border-box",
                padding: "8px",
                zIndex: 1,//4500,
                backgroundColor: "white",
                display: { xs: "flex", md: "none" }
            }} >
                <SearchModule />
            </Box>

            <div className={styles.mainFragment}>
                {
                    !loadingUser ?
                        <Grid container columnSpacing={2} rowSpacing={2} sx={{ marginTop: { sm: 0, md: 1 } }}>
                            <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
                                <AnalyticsMenu />
                            </Grid>
                            <Grid item container xs={12} sm={8} md={9} lg={9} xl={10}>
                                <ChartsContainer />
                            </Grid>
                        </Grid> :
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={true}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                }
            </div >
        </Fragment >
    );
}

export default DashboardPage;