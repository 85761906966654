import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Fragment, useEffect, useState } from 'react';
import { Box, linkClasses, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsResponse, setCenterCoordinates, setSelectedIndex } from '../../features/vehicles/vehiclesSlice';

const wideCell = {
    padding: 1
}

const Refuels = ({ refuels, selectedRefuel, setSelectedRefuel }) => {

    const analytics = useSelector(analyticsResponse);
    const dispatch = useDispatch();

    const handleSelectRefuel = (index) => {
        setSelectedRefuel(index)
        let location = analytics.fuel.refuel_locations[index]
        dispatch(setSelectedIndex(analytics.fuel.realtime.indexOf(refuels.refuel_dates[index])));
        dispatch(setCenterCoordinates(location))
    }

    return (
        <Box>
            <Typography variant="h6" sx={{ textAlign: "center", width: "100%", bgcolor: "primary.main" }} color="white">
                Refuels
            </Typography>

            <TableContainer sx={{ maxHeight: 190 }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={wideCell}>
                                Date
                            </TableCell>
                            <TableCell style={wideCell}>
                                Amount
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {refuels?.refuel_gallons?.map((item, index) => {

                            return (
                                <TableRow hover selected={selectedRefuel === index} key={"refuel" + item + index} onClick={(e) => handleSelectRefuel(index)}>
                                    <TableCell style={wideCell}>{refuels.refuel_dates[index]}</TableCell>
                                    <TableCell style={wideCell}>{refuels.refuel_gallons[index].toFixed(2) + " Gal"}</TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

const FuelAnalysis = ({ statistics }) => {

    return (
        <Box sx={{ marginTop: 2 }}>
            <Typography variant="h6" sx={{ textAlign: "center", width: "100%", bgcolor: "primary.main" }} color="white">
                Fuel Analysis
            </Typography>

            <TableContainer>
                <Table stickyHeader size="small">
                    <TableBody>
                        <TableRow hover>
                            <TableCell style={wideCell} sx={{ color: "black", fontWeight: "bolder" }} >Initial</TableCell>
                            <TableCell style={wideCell}>{statistics?.start_fuel ? statistics.start_fuel.toFixed(2) + " Gal" : ""}</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell style={wideCell} sx={{ color: "black", fontWeight: "bolder" }}>End</TableCell>
                            <TableCell style={wideCell}>{statistics?.end_fuel ? statistics.end_fuel.toFixed(2) + " Gal" : ""}</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell style={wideCell} sx={{ color: "black", fontWeight: "bolder" }}>Used</TableCell>
                            <TableCell style={wideCell}>{statistics?.total_consumption ? statistics.total_consumption.toFixed(2) + " Gal" : ""}</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell style={wideCell} sx={{ color: "black", fontWeight: "bolder" }}>Efficiency</TableCell>
                            <TableCell style={wideCell}>{statistics?.efficiency ? statistics.efficiency.toFixed(2) + " Km/Gal" : ""}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

const HighConsumption = ({ consumptions, selectedWarning, setSelectedWarning }) => {

    const analytics = useSelector(analyticsResponse);
    const dispatch = useDispatch();

    const handleSelectWarning = (index) => {
        setSelectedWarning(index)
        let lat = analytics.leaks.latitude[index]
        let lon = analytics.leaks.longitude[index]
        dispatch(setSelectedIndex(analytics.fuel.realtime.indexOf(consumptions.realTime[index])));
        dispatch(setCenterCoordinates([lat, lon]))
    }

    return (
        <Box sx={{ marginTop: 2 }}>
            <Typography variant="h6" sx={{ textAlign: "center", width: "100%", bgcolor: "primary.main" }} color="white">
                High Fuel Use
            </Typography>
            <TableContainer sx={{ maxHeight: 190 }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={wideCell}>
                                Distance
                            </TableCell>
                            <TableCell style={wideCell}>
                                Used Fuel
                            </TableCell>
                            <TableCell style={wideCell}>
                                Duration
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {consumptions?.usedFuel?.map((item, index) => {

                            return (
                                <TableRow hover selected={selectedWarning === index} key={"refuel" + item + index} onClick={(e) => handleSelectWarning(index)}>
                                    <TableCell style={wideCell}>{consumptions.distance[index].toFixed(2) + " Km"}</TableCell>
                                    <TableCell style={wideCell}>{consumptions.usedFuel[index].toFixed(2) + " Gal"}</TableCell>
                                    <TableCell style={wideCell}>{consumptions.timeWindow[index]}</TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

const AnalyticsMenu = () => {

    const analytics = useSelector(analyticsResponse);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(600));

    const [accordion, setAccordion] = useState(false);

    const [selectedRefuel, setSelectedRefuel] = useState(null);
    const [selectedWarning, setSelectedWarning] = useState(null);

    useEffect(() => {
        if (selectedRefuel != null) {
            setSelectedWarning(null)
        }
    }, [selectedRefuel])
    useEffect(() => {
        if (selectedWarning != null) {
            setSelectedRefuel(null);
        }
    }, [selectedWarning])

    return (
        <Fragment>
            {
                mobile ?
                    <Accordion
                        expanded={accordion}
                        onChange={(e) => setAccordion(!accordion)}

                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            sx={{ bgcolor: "secondary.main", maxHeight: 24 }}
                        >
                            <Typography variant="h6" sx={{ textAlign: "center", width: "100%" }} color="white">
                                Fuel Tools
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: 0 }}>
                            <Refuels refuels={analytics.fuel} selectedRefuel={selectedRefuel} setSelectedRefuel={setSelectedRefuel} />
                            <FuelAnalysis statistics={analytics.fuel} />
                            <HighConsumption consumptions={analytics.leaks} selectedWarning={selectedWarning} setSelectedWarning={setSelectedWarning} />
                        </AccordionDetails>
                    </Accordion>

                    :
                    <Box maxWidth="sm">
                        <Refuels refuels={analytics.fuel} selectedRefuel={selectedRefuel} setSelectedRefuel={setSelectedRefuel} />
                        <FuelAnalysis statistics={analytics.fuel} />
                        <HighConsumption consumptions={analytics.leaks} selectedWarning={selectedWarning} setSelectedWarning={setSelectedWarning} />
                    </Box>
            }
        </Fragment>

    );
}

export default AnalyticsMenu;