/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';

import { Divider, Drawer, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import LoginIcon from '@mui/icons-material/Login';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import styles from './ResponsiveAppBar.module.css';


import { useSelector, useDispatch } from 'react-redux';
import { selectId, selectLogged } from '../../../features/users/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchModule from '../../SearchModule/SearchModule';
import axios from 'axios';
import useUserValidation from '../../../hooks/useUserValidation/useUserValidation';




const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = () => {

  const { deleteSession } = useUserValidation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const location = useLocation();


  const dispatch = useDispatch();
  const islogged = useSelector(selectLogged);
  const userId = useSelector(selectId);

  const navigate = useNavigate();


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClickLogin = () => {
    setAnchorElNav(null);
    navigate("/login");
  };

  const handleClickLogout = () => {
    deleteSession();
  }

  const handleNavigate = (url) => {
    navigate(url);
  }

  useEffect(() => {
    console.log(location);
  }, [])

  return (
    <AppBar position="sticky">
      <Container sx={{ minWidth: "100%", maxWidth: "100%" }}>
        <Toolbar disableGutters>


          <Button onClick={(e) => islogged ? handleNavigate("/") : null} sx={{ display: { xs: 'none', md: 'flex', padding: 0, height: "100%" } }}>
            <img src="/static/logos/NavLogo.svg" alt="LOGO" className={styles.NavLogoMain} />
          </Button>

          {/*Left Menu only on mobile*/}
          <Box sx={{ flexGrow: 1, display: { xs: location.pathname !== "/login" ? 'flex' : 'none', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              id={styles.leftMenu}
              anchor="left"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                zIndex: 4501
              }}
            >
              <Box sx={{
                height: "56px",
                bgcolor: 'secondary.main',
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <Typography variant="h6" noWrap sx={{
                  textAlign: 'center',
                  fontWeight: 700,
                  color: 'white',
                  textDecoration: 'none',
                }}
                >MENU</Typography>
              </Box>

              <List sx={{
                minWidth: "200px",
                maxWidth: "200px",
              }}>
                {islogged ?
                  <>
                    <ListItem disablePadding onClick={handleClickLogout}>
                      <ListItemButton >
                        <ListItemIcon >
                          <LoginIcon />
                        </ListItemIcon>
                        <ListItemText primary="Cerrar Sesión" />
                      </ListItemButton>
                    </ListItem>
                  </>
                  :
                  null
                }
              </List>
            </Drawer>
          </Box>
          {/*Center logo only on mobile*/}
          <Box onClick={(e) => islogged ? handleNavigate("/") : null} sx={{
            display: { xs: 'flex', md: 'none' },
            flexGrow: 1,
          }}>
            <img src="/static/logos/NavLogo.svg" alt="LOGO" className={styles.NavLogoMain} />
          </Box>
          {/*Right logo only on mobile*/}
          <Icon sx={{ display: { xs: 'flex', md: 'none' } }} className={styles.logo}>
          </Icon>

          {/*Center Space only on desktop*/}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "center" }}>
            {
              islogged && location.pathname == "/" ?
                <SearchModule />
                : null}
          </Box>

          {/*Right buttons only on desktop*/}
          {
            location.pathname !== "/login" ?
              islogged ?
                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'block' } }}>
                  <Button variant="contained" color="secondary" onClick={handleClickLogout}>Cerrar Sesión</Button>
                </Box>
                :
                null
              : null
          }

        </Toolbar>
      </Container>
    </AppBar >
  );
};
export default ResponsiveAppBar;
