import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userReducer from "../features/users/userSlice";
import vehiclesReducer from "../features/vehicles/vehiclesSlice";


import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const userPersistConfig = {
    key: 'user',
    storage,
}



const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    vehicles: vehiclesReducer,
})


export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})

export const persistor = persistStore(store)