import React, { Fragment, useEffect } from "react";

import styles from './LoginPage.module.css';

import ResponsiveAppBar from "../../Navs/ResponsiveAppBar/ResponsiveAppBar ";
import Login from "../../Login/Login";





const LoginPage = () => {

    useEffect(() => {

    })

    return (
        <Fragment>
            <div className={styles.mainFragment}>
                <ResponsiveAppBar />
                <Login />
            </div>
        </Fragment>
    );
}

export default LoginPage;