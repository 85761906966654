import { Box, Button, Link, Paper, Typography } from '@mui/material';
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { Icon as lfIcon } from 'leaflet';
import { useEffect, useRef, useState } from 'react';

import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useSelector } from 'react-redux';
import { analyticsResponse, selectCenterCoordinates } from '../../features/vehicles/vehiclesSlice';
import { set } from 'date-fns';

import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

const gasIcon = new lfIcon({
    iconUrl: "/static/icons/refuel.svg",
    iconSize: [24, 24],
});
const warningIcon = new lfIcon({
    iconUrl: "/static/icons/warning.svg",
    iconSize: [24, 24],
});
const startIcon = new lfIcon({
    iconUrl: "/static/icons/start_flag.svg",
    iconSize: [24, 24],
});
const endIcon = new lfIcon({
    iconUrl: "/static/icons/end_flag.svg",
    iconSize: [24, 24],
});

const polyline = [
    [51.505, -0.1],
    [51.505, -0.09],
    [51.51, -0.1],
    [51.51, -0.12],
]

const polylineOptions = {
    stroke: true,
    color: "#FF6D00",
    weight: 3,
    opacity: 1
}

const EventComponent = ({ resize, route, center }) => {
    const thisMap = useMap();
    const centerCoordinates = useSelector(selectCenterCoordinates);

    const map = useMapEvents({
        zoom: (event) => {
            //console.log(event.target._zoom);
        }
    })

    useEffect(() => {
        let offset = 0
        console.log(resize);
        thisMap.invalidateSize();
        if (route.length > 2) {
            let maxLat = route.at(0)[1];
            let maxLon = route.at(0)[0];
            let minLat = route.at(0)[1];
            let minLon = route.at(0)[0];
            route.forEach(element => {
                if (element[1] > maxLat) {
                    maxLat = element[1] + offset
                }
                else if (element[1] < minLat) {
                    minLat = element[1] - offset
                }
                else if (element[0] > maxLon) {
                    maxLon = element[0] + offset
                }
                else if (element[0] < minLon) {
                    minLon = element[0] - offset
                }
            });
            thisMap.flyToBounds([[minLon, minLat], [maxLon, maxLat]]);
        }


    }, [resize, route, center]);

    useEffect(() => {
        if (centerCoordinates.length == 2) {
            thisMap.flyTo(centerCoordinates, 15)
        }
    }, [centerCoordinates])

    return null
}

const generateMapsLink = ([lat, lon]) => {
    return (`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lon}`)
}

const RefuelMarker = ({ item, index, setActivePopup }) => {
    const analytics = useSelector(analyticsResponse);
    const [refReady, setRefReady] = useState(false);
    let popupRef = useRef();
    const centerCoordinates = useSelector(selectCenterCoordinates);
    useEffect(() => {
        if (centerCoordinates.length > 0) {
            if (refReady) {
                if (centerCoordinates[0] == item[0] && centerCoordinates[1] == item[1]) {
                    popupRef.close();
                    popupRef.toggle();
                }
                else {
                    popupRef.close();
                }
            }
        }
    }, [centerCoordinates])

    return (
        <Marker position={item} icon={gasIcon} key={"refuel" + item + index}>
            <Popup ref={(r) => {
                popupRef = r;
                setRefReady(true);
            }}>
                <Typography variant="h6">
                    Refuel
                </Typography>
                <Typography variant="h6" sx={{ fontSize: 12 }}>
                    <strong>Amount:{' '}</strong> {analytics.fuel.refuel_gallons[index]?.toFixed(2)} Gal
                </Typography>
                <Typography variant="h6" sx={{ fontSize: 12 }}>
                    <strong>Date:{' '}</strong> {analytics.fuel.refuel_dates[index]}
                </Typography>
                <Link rel="noopener noreferrer" href={generateMapsLink(item)} target="_blank">
                    Go to maps
                </Link>
            </Popup>
        </Marker>
    )
}

const WarningMarker = ({ item, index, map }) => {
    const analytics = useSelector(analyticsResponse);
    const [refReady, setRefReady] = useState(false);
    let popupRef = useRef();
    const centerCoordinates = useSelector(selectCenterCoordinates);
    useEffect(() => {
        if (centerCoordinates.length > 0) {
            if (refReady) {
                if (centerCoordinates[0] == item[0] && centerCoordinates[1] == item[1]) {
                    popupRef.close();
                    popupRef.toggle();
                }
                else {
                    popupRef.close();
                }
            }
        }
    }, [centerCoordinates])

    return (
        <Marker position={item} icon={warningIcon} key={"warning" + item + index}>
            <Popup ref={(r) => {
                popupRef = r;
                setRefReady(true);
            }}>
                <Typography variant="h6">
                    Warning
                </Typography>
                <Typography variant="h6" sx={{ fontSize: 12 }}>
                    <strong>Amount:{' '}</strong> {analytics.leaks.usedFuel[index]?.toFixed(2)} Gal
                </Typography>
                <Typography variant="h6" sx={{ fontSize: 12 }}>
                    <strong>Distance:{' '}</strong> {analytics.leaks.distance[index]?.toFixed(2)} Km
                </Typography>
                <Typography variant="h6" sx={{ fontSize: 12 }}>
                    <strong>Time:{' '}</strong> {analytics.leaks.timeWindow[index]}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: 12 }}>
                    <strong>Date:{' '}</strong> {analytics.leaks.realTime[index]}
                </Typography>
                <Link rel="noopener noreferrer" href={generateMapsLink(item)} target="_blank">
                    Go to maps
                </Link>
            </Popup>
        </Marker>
    )
}

const ReactMap = ({ resize }) => {

    const analytics = useSelector(analyticsResponse);

    const [route, setRoute] = useState([]);
    const [refuels, setRefuels] = useState([]);
    const [warnings, setWarnings] = useState([]);

    const [center, setCenter] = useState(true);

    const GestureHandlingSetter = () => {
        /* eslint-disable */
        const map = useMap();
        map.gestureHandling.enable();
        map.addHandler("gestureHandling", GestureHandling);
        /* eslint-enable */
        return null;
    };

    useEffect(() => {
        if (analytics.fuel) {
            let routeCoordinates = []
            let fuelCoordinates = []
            let warningCoordinates = []
            routeCoordinates = analytics.fuel.longitude.map((item, index, array) => {

                return ([analytics.fuel.latitude[index], analytics.fuel.longitude[index]])
            });
            fuelCoordinates = analytics.fuel.refuel_locations;
            warningCoordinates = analytics.leaks.longitude.map((item, index, array) => {

                return ([analytics.leaks.latitude[index], analytics.leaks.longitude[index]])
            });

            setRoute(routeCoordinates);
            setRefuels(fuelCoordinates);
            setWarnings(warningCoordinates);
        }
    }, [analytics])

    useEffect(() => {
    }, [])


    return (
        <Paper sx={{ height: "400px", padding: 1 }}>
            <MapContainer center={[7.12539, -73.1198]} zoom={10} style={{ height: '100%', width: '100%' }}>
                <Button
                    variant="contained"
                    sx={{ position: 'absolute', bottom: 0, right: 0, zIndex: 4000 }}
                    onClick={(e) => setCenter(!center)}
                    color="secondary"
                >
                    <MyLocationIcon />
                </Button>
                <GestureHandlingSetter />
                <TileLayer
                    //attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://mt0.google.com/vt/lyrs=m,&hl=en&x={x}&y={y}&z={z}&s=Ga"
                />
                <EventComponent resize={resize} route={route} center={center} />
                <Polyline positions={route} pathOptions={polylineOptions} />
                {   //Route limits
                    route.length >= 2 ?
                        <>
                            < Marker position={route.at(0)} icon={startIcon}>
                                <Popup>
                                    <Typography variant="h6">
                                        Route Start
                                    </Typography>
                                    <Link rel="noopener noreferrer" href={generateMapsLink(route.at(0))} target="_blank">
                                        Go to maps
                                    </Link>
                                </Popup>
                            </Marker>
                            < Marker position={route.at(-1)} icon={endIcon}>
                                <Popup>
                                    <Typography variant="h6">
                                        Route End
                                    </Typography>
                                    <Link rel="noopener noreferrer" href={generateMapsLink(route.at(-1))} target="_blank">
                                        Go to maps
                                    </Link>
                                </Popup>
                            </Marker>
                        </>
                        : null
                }
                {   //Refuels
                    refuels.map((item, index) => {
                        return (
                            <RefuelMarker key={"refuel" + index} item={item} index={index} />
                        )
                    })
                }
                {   //Warnings
                    warnings.map((item, index) => {
                        return (
                            <WarningMarker key={"warning" + index} item={item} index={index} />
                        )
                    })
                }

            </MapContainer>

        </Paper >
    )
}

export default ReactMap;