import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearId, clearRole, clearToken, logout, selectLogged } from "../../features/users/userSlice";



const useUserValidation = () => {
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const logged = useSelector(selectLogged);
    const [loadingUser, setLoadingUser] = useState(true);

    const clearSession = () => {
        dispatch(clearId());
        dispatch(clearRole());
        dispatch(clearToken());
        dispatch(logout());
    }

    const checkToken = (callback) => {
        axios.get("/api/session", { withCredentials: true })
            .then(result => result.data)
            .then(response => {
                callback();
                setLoadingUser(false);
            })
            .catch(error => {
                clearSession();
                navigate("/login");
                setLoadingUser(false);
            })
    }

    const deleteSession = () => {
        axios.delete("/api/session", { withCredentials: true })
            .then(result => result.data)
            .then(response => {
                clearSession();
                navigate("/login");
            })
            .catch((error) => {
                clearSession();
                navigate("/login");
            })
    }

    return { checkToken, deleteSession, logged, loadingUser };

}

export default useUserValidation;