import React, { useEffect, useRef, useState } from 'react';
import 'chartjs-adapter-date-fns'
import zoom from 'chartjs-plugin-zoom'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale,
    Filler,
    Title
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { Box, Button, ButtonGroup, IconButton, Paper, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsResponse, selectSelectedIndex, setCenterCoordinates, setSelectedIndex } from '../../features/vehicles/vehiclesSlice';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import { useLongPress } from 'use-long-press';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale,
    Filler,
    Title,
    zoom
);

const refuelUrl = "/static/icons/refuel.svg";
const warningUrl = "/static/icons/warning.svg";
const size = 20
const refuelIcon = new Image(size, size);
refuelIcon.src = refuelUrl;
const warningIcon = new Image(size, size);
warningIcon.src = warningUrl;


const defaultData = {
    labels: [],
    datasets: [],
};
const defaultOptions = (mobile) => {
    return ({
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        tooltipEvents: ["click"],
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        scales: {
            x: {
                type: "time",
                time: {
                    unit: "day"
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Value'
                },


            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Analytics Chart'
            },
            zoom: {
                pan: {
                    enabled: mobile ? false : true,
                    mode: 'x',
                },
                zoom: {
                    drag: mobile ? false : true,
                    mode: 'x',
                    wheel: {
                        enabled: mobile ? false : true,
                    }
                }
            }
        },
    })
};
const defaultPlugins = [{
    beforeLayout: chart => chart.data.datasets.forEach((ds, i) => chart.config.options.scales[ds.yAxisID].display = !chart.getDatasetMeta(i).hidden),
    id: 'chartAreaBorder',
    beforeDraw(chart, args, options) {
        const { ctx, chartArea: { left, top, width, height } } = chart;
        if (chart.options.plugins.zoom.zoom.wheel.enabled) {
            ctx.save();
            ctx.strokeStyle = '#0e2146';
            ctx.lineWidth = 1;
            ctx.strokeRect(left, top, width, height);
            ctx.restore();
        }
    }
}]



const ReactChart = () => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(600));

    const analytics = useSelector(analyticsResponse);
    const selectedIndex = useSelector(selectSelectedIndex);

    const dispatch = useDispatch();

    const [options, setOptions] = useState(defaultOptions);
    const [data, setData] = useState(defaultData);
    const [plugins, setPlugins] = useState(defaultPlugins);

    const [started, setStarted] = useState(false);

    const chartRef = useRef(null);



    const handleResetZoom = () => {
        if (chartRef && chartRef.current) {
            chartRef.current.resetZoom();
        }
    };
    const handlePan = (amount) => {
        if (chartRef && chartRef.current) {
            chartRef.current.pan({ x: amount }, undefined, 'default');
        }
    };
    const handleZoom = (amount) => {
        if (chartRef && chartRef.current) {
            chartRef.current.zoom(amount);
        }
    };
    const handleDblClick = (e) => {
        if (chartRef && chartRef.current) {
            const chart = chartRef.current;
            console.log(chart);
            chart.options.plugins.zoom.zoom.drag = !chart.options.plugins.zoom.zoom.drag;
            chart.options.plugins.zoom.zoom.wheel.enabled = !chart.options.plugins.zoom.zoom.wheel.enabled;
            chart.options.plugins.zoom.zoom.pinch.enabled = !chart.options.plugins.zoom.zoom.pinch.enabled;
            chart.options.plugins.zoom.pan.enabled = !chart.options.plugins.zoom.pan.enabled;
            chart.update();
        }
    };
    const generatePlugins = (analytics) => {
        let refuels = []
        let warnings = []
        if (analytics.fuel) {
            refuels = analytics.fuel.refuel_dates;
            warnings = analytics.leaks.realtime;
        }

        return (
            [{
                beforeLayout: chart => chart.data.datasets.forEach((ds, i) => chart.config.options.scales[ds.yAxisID].display = !chart.getDatasetMeta(i).hidden),
                afterUpdate: function (chart) {
                    const meta = chart.getDatasetMeta(0);
                    console.log("META:", meta)
                    console.log("METADATA:", meta.data)

                    meta.data.forEach((item, index, array) => {
                        //console.log(refuels, meta._dataset.data[index])
                        if (meta._dataset.data[index].type == "refuel") {
                            //console.log("MATCH");
                            item.options.pointStyle = "triangle";
                            item.options.pointRadius = 10
                        }
                    })
                    /*
                    meta.data[0]._model.pointStyle = img;
                    meta.data[1]._model.pointStyle = 'rect';
                    meta.data[2]._model.pointStyle = 'triangle';
                    meta.data[3]._model.pointStyle = 'circle';
                    */
                }
            }]
        )
    };
    const generateDataSet = (type, label, color, bgColor, fill, data, yAxis, pointRadius) => {


        return (
            {
                type: type,
                label: label,
                borderColor: color,
                backgroundColor: bgColor,
                borderWidth: 2,
                fill: fill,
                data: data,
                yAxisID: yAxis,
                pointRadius: data.map((item) => item.type == "refuel" ? 5 : item.type == "warning" ? 5 : pointRadius),
                pointStyle: data.map((item) => item.type == "refuel" ? refuelIcon : item.type == "warning" ? warningIcon : "circle"),
                pointHitRadius: data.map((item) => item.type == "refuel" ? 5 : item.type == "warning" ? 5 : 4)
            }
        )
    };
    const generateData = (datasets) => {
        return (
            {
                labels: [],
                datasets: datasets
            }
        )
    };
    const generateScale = (id, type, title) => {
        let dict = {};
        dict[id] = {
            type: type,
            display: false,
            position: 'left',
            stack: mobile ? "Stack" : title,
            stackWeight: title === "Speed" ? 1 : title === "Fuel" ? 1 : 1,
            beginAtZero: true,
            title: {
                display: true,
                text: title,
            },
            ticks: {
                reverse: false,
                beginAtZero: true,
                callback: (yValue) => {
                    return Math.floor(yValue); // format to your liking
                },
            },
            gridLines: {
                display: false,
                drawBorder: false //<- set this
            }
        }
        return (dict)
    };
    const generateOptions = (scales, analytics) => {
        scales.push(
            {
                x: {
                    display: true,
                    type: "time",
                    ticks: {
                        autoSkip: true,
                        autoSkipPadding: 50,
                        maxRotation: 0
                    },
                    time: {
                        displayFormats: {
                            hour: 'MMM dd HH:mm',
                            minute: 'HH:mm',
                            second: 'HH:mm:ss'
                        }
                    }
                },
            }
        )
        const res = scales.reduce((acc, el) => {
            for (let key in el) {
                acc[key] = el[key];
            };
            return acc;
        }, {})

        return (
            {
                responsive: true,
                title: { text: "Analytics Graph", display: true },
                animation: false,
                maintainAspectRatio: false,
                events: ['mouseout', 'click', 'touchstart', 'touchmove'],
                interaction: {
                    mode: 'index',
                    intersect: true,
                },
                stacked: true,
                scales: res,
                plugins: {
                    title: {
                        display: true,
                        text: 'Analytics Chart'
                    },
                    zoom: {
                        pan: {
                            enabled: false,
                            mode: 'x',
                        },
                        zoom: {
                            drag: false,
                            mode: 'x',
                            wheel: {
                                enabled: false,
                            },
                            pinch: {
                                enabled: false,
                            }
                        },
                        limits: {
                            "Fuel": {
                                min: 0,
                                max: analytics.fuel ? Math.max({...analytics}.fuel?.denoised) + 1 : 10
                            },
                            "Altitude": {
                                min: 0,
                                max: analytics.fuel ? Math.max({...analytics}.fuel?.altitude) + 2 : 10
                            }
                        }
                    },
                    tooltip: {
                        callbacks: {
                            beforeTitle: (Datapoint) => {
                                console.log(Datapoint);
                                return Datapoint[0].raw.x
                            },
                            title: (xDatapoint) => {
                                if (xDatapoint[0].raw.type === "refuel") {
                                    console.log(xDatapoint[0]);
                                    let lon = analytics.fuel.longitude[xDatapoint[0].dataIndex];
                                    let lat = analytics.fuel.latitude[xDatapoint[0].dataIndex];
                                    dispatch(setCenterCoordinates([lat, lon]))
                                    let index = analytics.fuel.refuel_dates.indexOf(xDatapoint[0].raw.x);
                                    return "Refuel: " + analytics.fuel.refuel_gallons[index].toFixed(2) + " Gal"
                                }
                                else if (xDatapoint[0].raw.type === "warning") {
                                    let lon = analytics.fuel.longitude[xDatapoint[0].dataIndex];
                                    let lat = analytics.fuel.latitude[xDatapoint[0].dataIndex];
                                    dispatch(setCenterCoordinates([lat, lon]))
                                    let index = analytics.leaks.realTime.indexOf(xDatapoint[0].raw.x);
                                    return "Warning: " + analytics.leaks.usedFuel[index].toFixed(2) + " Gal"
                                }
                                return null
                            },
                            label: (yDatapoint) => {
                                if (yDatapoint.raw.type == "refuel" || yDatapoint.raw.type == "event_refuel")
                                    return null
                                return undefined
                            },
                        }
                    },
                    datalabels: {
                        display: function (context) {
                            return context.dataset.data[context.dataIndex] > 0; // only return if greater than zero
                        }
                    }
                }
            }
        )
    };

    const moveForward = useLongPress(() => { }, {
        /*onStart: event => console.log('Long press started'),
        onFinish: event => console.log('Long press finished'),
        onCancel: event => console.log('Press cancelled'),
        onMove: event => console.log('Detected mouse or touch movement'),
        filterEvents: event => true, // All events can potentially trigger long press
        threshold: 1000,
        captureEvent: true,
        cancelOnMovement: false,
        detect: 'both',*/
    });
    const moveBackward = useLongPress(() => { }, {
        /*onStart: event => console.log('Long press started'),
        onFinish: event => console.log('Long press finished'),
        onCancel: event => console.log('Press cancelled'),
        onMove: event => console.log('Detected mouse or touch movement'),
        filterEvents: event => true, // All events can potentially trigger long press
        threshold: 1000,
        captureEvent: true,
        cancelOnMovement: false,
        detect: 'both',*/
    });


    useEffect(() => {
        setStarted(true);
    }, [])

    useEffect(() => {
        console.log("CHART: ", analytics);
        if (analytics.fuel) {
            let fuel = [];
            let altitude = [];
            let speed = [];

            fuel = analytics.fuel.realtime.map((item, index, array) => {
                return ({
                    x: analytics.fuel.realtime[index],
                    y: analytics.fuel.denoised[index].toFixed(2),
                    type: analytics.fuel.refuel_dates.includes(analytics.fuel.realtime[index]) ?
                        "refuel"
                        :
                        analytics.leaks.realTime.includes(analytics.fuel.realtime[index]) ?
                            "warning"
                            :
                            "fuel"
                })
            })
            altitude = analytics.fuel.realtime.map((item, index, array) => {
                return ({
                    x: analytics.fuel.realtime[index],
                    y: analytics.fuel.altitude[index].toFixed(2),
                    type: analytics.fuel.refuel_dates.includes(analytics.fuel.realtime[index]) ?
                        "event_refuel"
                        :
                        analytics.leaks.realTime.includes(analytics.fuel.realtime[index]) ?
                            "event_warning"
                            :
                            "altitude"
                })
            })
            speed = analytics.fuel.speed.map((item, index, array) => {
                return ({
                    x: analytics.fuel.realtime[index],
                    y: analytics.fuel.speed[index].toFixed(0),
                    type: analytics.fuel.refuel_dates.includes(analytics.fuel.realtime[index]) ?
                        "event_refuel"
                        :
                        analytics.leaks.realTime.includes(analytics.fuel.realtime[index]) ?
                            "event_warning"
                            :
                            "speed"
                })
            })

            let dsFuel = generateDataSet("line", "Fuel", "rgb(0,0,127)", "rgba(0,0,127,0.5)", false, fuel, "Fuel", 0.01);
            let scFuel = generateScale("Fuel", "linear", "Fuel");
            let dsAltitude = generateDataSet("line", "Altitude", "rgba(0,25,127,0)", "rgba(0,25,127,0.25)", true, altitude, "Altitude", 0);
            let scAltitude = generateScale("Altitude", "linear", "Altitude");
            let dsSpeed = generateDataSet("line", "Speed", "rgba(255,0,255,0.5)", "rgba(255,0,255,0.5)", false, speed, "Speed", 0);
            let scSpeed = generateScale("Speed", "linear", "Speed");

            let chartData = generateData([dsFuel, dsSpeed, dsAltitude]);
            let chartOptions = generateOptions([scAltitude, scSpeed, scFuel], analytics);
            let newPlugins = generatePlugins(analytics);
            console.log(newPlugins);

            //setPlugins(newPlugins);
            setOptions(chartOptions);
            setData(chartData);

        }
    }, [analytics])

    useEffect(() => {
        if (chartRef && chartRef.current.tooltip && selectedIndex && started) {
            console.log(chartRef);
            let tooltip = chartRef.current.tooltip;

            if (tooltip.getActiveElements().length > 0) {
                tooltip.setActiveElements([], { x: 0, y: 0 });
            }
            const chartArea = chartRef.current.chartArea;
            tooltip.setActiveElements([
                {
                    datasetIndex: 0,
                    index: selectedIndex,
                }, {
                    datasetIndex: 1,
                    index: selectedIndex,
                }
            ],
                {
                    x: (chartArea.left + chartArea.right) / 2,
                    y: (chartArea.top + chartArea.bottom) / 2,
                });

            chartRef.current.update();
        }
    }, [selectedIndex])

    return (
        <Paper sx={{ position: "relative", height: "400px" }} onDoubleClick={handleDblClick} >

            {mobile ?
                <>
                    <ButtonGroup variant="contained" orientation="vertical" size='small' color="primary" sx={{ position: "absolute", top: 5, left: 5, bgcolor: "white" }}>
                        <IconButton
                            sx={{
                                width: 28,
                                height: 28,
                            }}
                            onClick={() => handleZoom(1.5)}
                        >
                            <AddIcon sx={{ color: "primary.main" }} />
                        </IconButton>
                        <IconButton
                            sx={{
                                width: 28,
                                height: 28,
                            }}
                            onClick={() => handleZoom(0.5)}
                        >
                            <RemoveIcon sx={{ color: "primary.main" }} />
                        </IconButton>
                    </ButtonGroup>

                    <ButtonGroup variant="contained" size='small' color="primary" sx={{ position: "absolute", top: 5, right: 5 }}>
                        <div {...moveForward()}>
                            <IconButton
                                sx={{
                                    width: 36,
                                    height: 28,
                                }}
                                onClick={() => handlePan(25)}

                            >
                                <FastRewindIcon sx={{ color: "primary.main" }} />
                            </IconButton>
                        </div>
                        <IconButton
                            sx={{
                                width: 28,
                                height: 28,
                            }}
                            onClick={handleResetZoom}
                        >
                            <FullscreenIcon sx={{ color: "primary.main" }} />
                        </IconButton>
                        <div {...moveBackward()}>
                            <IconButton
                                sx={{
                                    width: 36,
                                    height: 28,
                                }}
                                onClick={() => handlePan(-25)}
                            >
                                <FastForwardIcon sx={{ color: "primary.main" }} />
                            </IconButton>
                        </div>
                    </ButtonGroup>

                </>
                :
                <Button variant="contained" size='small' color="primary" sx={{ position: "absolute", top: 10, right: 10 }} onClick={handleResetZoom}>Reset Zoom</Button>
            }

            <Chart ref={chartRef} options={options} data={data} plugins={plugins} />

        </Paper >

    )
}

export default ReactChart;
