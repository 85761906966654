import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { useDispatch } from 'react-redux';
import { login, setToken, setId, setRole } from '../../features/users/userSlice';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

var qs = require('qs');


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://safestart.com.co/">
                SafeStart SAS
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Login = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = new FormData(event.currentTarget);
        let data = {
            email: form.get('email'),
            password: form.get('password'),
        };
        console.log(data);
        setLoading(true);
        //let remember = form.get('remember')
        axios.post("/api/session", data, { withCredentials: true, headers: { 'content-type': 'application/x-www-form-urlencoded' } })
            .then(result => {
                console.log(result);
                return result.data
            })
            .then(response => {
                console.log(response);
                setLoading(false);
                dispatch(setToken(`access_token_cookie=${response.token}`));
                dispatch(login());
                dispatch(setId(response.id));
                dispatch(setRole(response.role));
                navigate("/");
                setErrors(false);
            })
            .catch(error => {
                console.log(error);
                setErrors(true);
                setLoading(false);
            })
    };

    return (
        <Box sx={{ overflow: "auto", flex: 1, display: "flex", flexDirection: "column" }}>
            <Grid container component="main" sx={{ flex: 1 }}>
                <Grid item xs={false} sm={4} md={7} sx={{
                    backgroundImage: `url(static/backgrounds/gas.jpg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                />
                <Grid item xs={12} sm={8} md={5} elevation={6}>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Iniciar Sesión
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                error={errors}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                error={errors}
                                helperText={errors && "Invalid Email/Password"}
                            />
                            {/*<FormControlLabel
                                control={<Checkbox value="true" color="primary" name="remember" />}
                                label="Recordarme"
                            />*/}
                            <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={loading}
                                >
                                    Iniciar Sesión
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "primary.ligth",
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>

                            {/*<Grid container>
                                <Grid item xs={12} md>
                                    <Link href="#" variant="body2">
                                        Olvidó su contraseña?
                                    </Link>
                                </Grid>
                                <Grid item xs={12} md>
                                    <Link href="#" variant="body2">
                                        {"No tiene una cuenta? Regístrese"}
                                    </Link>
                                </Grid>
                            </Grid>*/}
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
export default Login;