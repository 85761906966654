import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

export const vehiclesSlice = createSlice({
    name: 'vehicles',
    initialState: {
        vehicleList: [],
        selectedVehicle: "",
        startDate: "",
        endDate: "",
        analyticsResponse: {},
        centerCoordinates: [],
        selectedIndex: null,
    },
    reducers: {
        setVehiclesList: (state, action) => {
            state.vehicleList = action.payload
        },
        clearVehicles: state => {
            state.vehicleList = [];
        },
        setSelectedVehicle: (state, action) => {
            state.selectedVehicle = action.payload;
        },
        setStartDateRedux: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDateRedux: (state, action) => {
            state.endDate = action.payload;
        },
        setAnalyticsResponse: (state, action) => {
            state.analyticsResponse = action.payload;
        },
        setCenterCoordinates: (state, action) => {
            state.centerCoordinates = action.payload;
        },
        setSelectedIndex: (state, action) => {
            state.selectedIndex = action.payload;
        },
        resetVehicleRedux: (state) => {
            state.vehicleList = [];
            state.selectedVehicle = "";
            state.analyticsResponse = {};
            state.centerCoordinates = [];
            state.selectedIndex = null
        }
    }
})

export const { setVehiclesList, clearVehicles, setSelectedVehicle, setStartDateRedux, setEndDateRedux, setAnalyticsResponse, setCenterCoordinates, setSelectedIndex, resetVehicleRedux } = vehiclesSlice.actions

export const fetchVehicles = () => (dispatch) => {
    axios.get("/api/devices", { withCredentials: true })
        .then(result => result.data)
        .then(response => {
            console.log(response);
            dispatch(setVehiclesList(response));
        })
        .catch(error => {
            console.log(error)
        })
}
export const fetchAnalytics = (data) => (dispatch) => {
    let dataForm = {
        device_id: data.vehicles.selectedVehicle,
        from_date: data.vehicles.startDate,
        to_date: data.vehicles.endDate
    }
    console.log(dataForm);

    axios.post("api/refuel/analytics", dataForm,
        {
            headers: {
                'Access-Control-Allow-Credentials': true,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        .then(result => result.data)
        .then(response => {
            console.log(response);
            dispatch(setAnalyticsResponse(response));
        })
        .catch(error => {
            console.log(error);
            dispatch(setAnalyticsResponse({}));
        })

}

export const selectVehiclesList = (state) => state.vehicles.vehicleList;
export const selectedVehicle = (state) => state.vehicles.selectedVehicle;
export const startDate = (state) => state.vehicles.startDate;
export const endDate = (state) => state.vehicles.endDate;
export const analyticsResponse = (state) => state.vehicles.analyticsResponse;
export const selectCenterCoordinates = (state) => state.vehicles.centerCoordinates;
export const selectSelectedIndex = (state) => state.vehicles.selectedIndex;


export default vehiclesSlice.reducer