import { Box, Button, Grid, MenuItem, Paper, Select, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react"
import ReactChart from "../ReactChart/ReactChart"
import ReactMap from "../ReactMap/ReactMap";
import Iframe from 'react-iframe'



const ChartsContainer = () => {

    const [chartsAmount, setChartsAmount] = useState(1);
    const [chartsPherRow, setChartsPherRow] = useState(2);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(600));
    const desktop = useMediaQuery(theme.breakpoints.up("lg"));

    const handleChartsAmount = (value) => {
        if (chartsAmount + value >= 1) {
            setChartsAmount(chartsAmount + value);
        }
    }


    return (
        <Grid container rowSpacing={2} columnSpacing={2} alignItems="flex-start" sx={{ height: "fit-content" }}>
            {
                desktop ?
                    <Grid item xs={12}>
                        <Box sx={{ bgcolor: "primary.main", display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                            <Typography variant="h5" color="white">
                                Chart tools
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Button size="small" variant="contained" color="secondary" onClick={(e) => { handleChartsAmount(1) }}>
                                    Add Chart
                                </Button>
                                <Button size="small" variant="contained" color="secondary" onClick={(e) => { handleChartsAmount(-1) }} disabled={chartsAmount <= 1} sx={{ marginLeft: 2 }}>
                                    Remove Chart
                                </Button>
                                <Typography variant="h6" color="white" sx={{ marginLeft: 2 }}>
                                    Charts pher row
                                </Typography>
                                <Select
                                    variant="standard"
                                    size="small"
                                    disableUnderline
                                    sx={{ bgcolor: "white", marginLeft: 2, borderRadius: 1, border: "none" }}
                                    id="demo-simple-select"
                                    value={chartsPherRow}
                                    label="Age"
                                    onChange={(e) => setChartsPherRow(e.target.value)}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                </Select>
                            </Box>
                        </Box>
                    </Grid>
                    :
                    null
            }

            {
                [...Array(chartsAmount)].map((item, index) => {
                    return (
                        <Grid key={"chart" + index} item md={12} lg={chartsPherRow == 2 ? 6 : 12} sx={{ width: "100%" }}>
                            <ReactChart />
                        </Grid>
                    )
                })
            }
            <Grid item md={12} lg={chartsPherRow == 2 ? 6 : 12} sx={{ width: "100%" }}>
                <ReactMap resize={chartsPherRow} />
            </Grid>
        </Grid>
    )
}

export default ChartsContainer