import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        id: null,
        logged: false,
        role: "client",
        token: null,
    },
    reducers: {
        login: state => {
            state.logged = true;
        },
        logout: state => {
            state.logged = false;
        },
        setId: (state, action) => {
            state.id = action.payload;
        },
        clearId: state => {
            state.id = null;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        clearToken: state => {
            state.token = null;
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        clearRole: state => {
            state.role = "client";
        }
    }
})

export const { login, logout, setId, clearId, setToken, clearToken, setRole, clearRole } = userSlice.actions

export const setIdAsync = (id) => (dispatch) => {
    setTimeout(() => {
        dispatch(setId(id))
    }, 1000)
}

export const selectLogged = (state) => state.user.logged
export const selectId = (state) => state.user.id
export const selectRole = (state) => state.user.role
export const selectToken = (state) => state.user.token

export default userSlice.reducer